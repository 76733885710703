import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA9BcSOC-fTnx8RnvbpcHf4VmO8NZqUeYE",
  authDomain: "bdo-dashboard-84ec8.firebaseapp.com",
  projectId: "bdo-dashboard-84ec8",
  storageBucket: "bdo-dashboard-84ec8.appspot.com",
  messagingSenderId: "386923064235",
  appId: "1:386923064235:web:45edd1a9f4f7e9b67240ee",
  measurementId: "G-JY0GKVYZQD",
  databaseURL: "https://bdo-dashboard-84ec8-default-rtdb.europe-west1.firebasedatabase.app/"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// Used for creating child accounts, primarily
export const firebaseSecondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary');
export const firebaseAuth = firebaseApp.auth();
export const firebaseSecondaryAuth = firebaseSecondaryApp.auth();
export const firebaseDb = firebaseApp.database();
export const firebaseFunc = firebaseApp.functions();
export const firestore = firebaseApp.firestore();
