import React, { useMemo, useContext, useCallback, FC, useState } from "react";
import AppHeaderNav from "../components/AppHeaderNav";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { ComponentRouteListItem } from "../components/AppHeaderNav";
import { AuthContext, filterRouteAllows, logout } from "../core/auth";
import { Home } from "./public-pages/Home";
import Button from "@mui/material/Button";
import SearchBar from "../components/core/SearchBar";
import RecipeCard from "../components/core/RecipeCard";
import { firestore } from "../core/firebase";
import jsonData from "./items.json";
import BDORecipes from "./bdo_recipes_full.json";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import { MeiliSearch } from "meilisearch";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import useWindowDimensions from '../hooks/useWindowDimensions';


const routes: Array<ComponentRouteListItem> = [
  {
    name: "Admin Page",
    url: "/",
    exact: true,
    comp: Home,
    allowRoles: ["admin"],
    id: "admin-page",
  },
];

const UserDash: FC<RouteComponentProps> = ({ history }) => {
  const { user } = useContext(AuthContext);
  const { width } = useWindowDimensions();
  const client = new MeiliSearch({
    host: "https://droplet.alkan.dev",
    apiKey: "OTUwMmMyYTBkOWExM2YwMWI2Njg4YzQw",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [searchHits, setSearchHits] = useState([] as any[]);
  const [selectedRecipes, setSelectedRecipes] = useState([] as any[]);
  const usrRouteListItems = useMemo(
    () => filterRouteAllows((user && user.roles) || [], routes),
    [user]
  );

  const usrRoutes = useMemo(
    () => usrRouteListItems.map(({ comp: _, ...route }) => route),
    [usrRouteListItems]
  );

  const onLogout = useCallback(
    () => void logout().then(() => void history.push("/")),
    [history]
  );

  const onSearchBarValueChange = useCallback((value) => {
    handleSearch(value);
  }, []);

  const handleSearch = async (value: string) => {
    const index = client.index("recipes");
    const search = await index.search(value);
    setSearchHits(search.hits);
  };

  const syncFirestore = async () => {
    setIsLoading(true);
    try {
      for (const item of jsonData.items) {
        await firestore
          .collection("items")
          .where("id", "==", item.id)
          .get()
          .then((resultSnapShot) => {
            if (resultSnapShot.empty) {
              firestore
                .collection("items")
                .add({
                  id: item.id,
                  name: item.name,
                  grade: item.grade,
                  icon: item.icon,
                })
                .then(function (docRef) {
                  console.log("Document written with ID: ", docRef.id);
                })
                .catch(function (error) {
                  console.error("Error adding document: ", error);
                });
            } else {
              //Already registered
              console.log(`item with id ${item.id} already exist, skipping...`);
            }
          });
      }
    } catch (error) {
      console.log("try error");
    } finally {
      setIsLoading(false);
    }
  };

  const syncSearchEngine = async () => {
    setIsLoading(true);
    const snapshot = await firestore
      .collection("items")
      .get();
    if (snapshot.empty) {
      console.log("No matching documents.");
      return;
    }
    try {
      for (const doc of snapshot.docs) {
        console.log(doc.id, "=>", doc.data());
        // An index is where the documents are stored.
        const index = client.index("items");
        const documents = [{ id: doc.data().id, name: doc.data().name }];
        // If the index 'items' does not exist, Meilisearch creates it when you first add the documents.
        const response = await index.addDocuments(documents);
        console.log("sync search engine result: ", response);
      }
    } catch (error) {
      console.log("try error");
    } finally {
      setIsLoading(false);
    }

    snapshot.forEach(async (doc) => {
      console.log(doc.id, "=>", doc.data());
      // An index is where the documents are stored.
      const index = client.index("items");
      const documents = [{ id: doc.data().id, name: doc.data().name }];
      // If the index 'items' does not exist, Meilisearch creates it when you first add the documents.
      const response = await index.addDocuments(documents);
      console.log("sync search engine result: ", response);
    });
  };

  const syncAllRecipesWithSearchEngine = async () => {
    setIsLoading(true);
    try {
      for (const recipe of BDORecipes.recipes) {
        console.log(recipe.product.name, "=>", recipe.product);
        // An index is where the documents are stored.
        const index = client.index("recipes");
        const documents = [{ id: recipe.product.id, name: recipe.product.name }];
        // If the index 'items' does not exist, Meilisearch creates it when you first add the documents.
        const response = await index.addDocuments(documents);
        console.log("sync search engine result: ", response);
      }
    } catch (error) {
      console.log("try error");
    } finally {
      setIsLoading(false);
    }

  }

  const handleRecipeRefresh = async (recipe: any) => {
    const requestOptions2: RequestInit = {
      method: 'GET',
      redirect: 'follow'
    };
    const priceNeededItems = recipe.ingredients.map((ing: any, index: number) => {
      return ing.item[0]
    })
    const promises: Promise<string>[] = [];

    priceNeededItems.forEach((item: any) => {
      promises.push(
        fetch(`https://api.arsha.io/v2/mena/GetMarketPriceInfo?id=${item.id.toString()}&sid=0`, requestOptions2)
          .then(response => response.text())
      )
    })

    await Promise.all(promises).then((results) => {
      for (let index = 0; index < results.length; index++) {
        const result = results[index];
        const historyData = JSON.parse(result)['history'];
        const timestamps = Object.keys(historyData);
        const today = new Date().getTime();
        const closest = timestamps.reduce((a, b) => {
          const adiff = parseInt(a) - today;
          return adiff > 0 && adiff < parseInt(b) - today ? a : b;
        })
        recipe.ingredients[index].item[0].price = historyData[closest];
        recipe.ingredients[index].item[0].lastUpdated = closest;
      }
    })

    fetch(`https://api.arsha.io/v2/mena/GetMarketPriceInfo?id=${recipe.product.id.toString()}&sid=0`, requestOptions2)
      .then(response => response.text())
      .then(result => {
        const historyData = JSON.parse(result)['history'];
        const timestamps = Object.keys(historyData);
        const today = new Date().getTime();
        const closest = timestamps.reduce((a, b) => {
          const adiff = parseInt(a) - today;
          return adiff > 0 && adiff < parseInt(b) - today ? a : b;
        })
        recipe.product.price = historyData[closest];
        recipe.product.lastUpdated = closest;
      })
      .catch(error => console.log('error', error))
  }

  const onSearchBarOptionSelect = async (value: number) => {
    const recipe: any = BDORecipes.recipes.find(x => x.product.id === value);
    if (!recipe) {
      return
    }
    console.log('selected recipe: ', recipe);
    setIsLoading(true);
    const requestOptions2: RequestInit = {
      method: 'GET',
      redirect: 'follow'
    };
    const priceNeededItems = recipe.ingredients.map((ing: any, index: number) => {
      return ing.item[0]
    })
    const promises: Promise<string>[] = [];

    priceNeededItems.forEach((item: any) => {
      promises.push(
        fetch(`https://api.arsha.io/v2/mena/GetMarketPriceInfo?id=${item.id.toString()}&sid=0`, requestOptions2)
          .then(response => response.text())
      )
    })

    await Promise.all(promises).then((results) => {
      for (let index = 0; index < results.length; index++) {
        const result = results[index];
        const historyData = JSON.parse(result)['history'];
        const timestamps = Object.keys(historyData);
        const today = new Date().getTime();
        const closest = timestamps.reduce((a, b) => {
          const adiff = parseInt(a) - today;
          return adiff > 0 && adiff < parseInt(b) - today ? a : b;
        })
        recipe.ingredients[index].item[0].price = historyData[closest];
        recipe.ingredients[index].item[0].lastUpdated = closest;
      }
    })

    fetch(`https://api.arsha.io/v2/mena/GetMarketPriceInfo?id=${value.toString()}&sid=0`, requestOptions2)
      .then(response => response.text())
      .then(result => {
        const historyData = JSON.parse(result)['history'];
        const timestamps = Object.keys(historyData);
        const today = new Date().getTime();
        const closest = timestamps.reduce((a, b) => {
          const adiff = parseInt(a) - today;
          return adiff > 0 && adiff < parseInt(b) - today ? a : b;
        })
        recipe.product.price = historyData[closest];
        recipe.product.lastUpdated = closest;
        setSelectedRecipes([recipe, ...selectedRecipes])
      })
      .catch(error => console.log('error', error))
      .finally(() => setIsLoading(false));
  }

  const renderSyncButtons = () => {
    return (
      <Stack spacing={2} direction="row">
        <LoadingButton
          variant="contained"
          disableElevation
          disabled
          loading={false}
          onClick={() => syncSearchEngine()}
        >
          Sync Search Engine
        </LoadingButton>
        <LoadingButton
          variant="contained"
          disableElevation
          disabled
          loading={false}
          onClick={() => syncFirestore()}
        >
          Sync Firestore
        </LoadingButton>
        <LoadingButton
          variant="contained"
          disableElevation
          disabled
          loading={false}
          onClick={() => syncAllRecipesWithSearchEngine()}
        >
          Sync Recipes with Search Engine
        </LoadingButton>
      </Stack>
    )
  }

  const handleDeleteRecipe = (id: number) => {
    const filtered = selectedRecipes.filter(x => x.product.id !== id);
    setSelectedRecipes(filtered);
  }

  const RenderSelectedRecipes = () => {
    if (selectedRecipes.length > 0) {
      return (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12, xl: 16 }}>
            {selectedRecipes.map((recipe, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <RecipeCard recipe={recipe} onRecipeDelete={(id) => handleDeleteRecipe(id)} onRecipeRefresh={(recipe) => handleRecipeRefresh(recipe)} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )
    }
    else {
      return (
        <Box display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width={"100%"}
          style={{ flexGrow: 1 }}>
          <Typography variant="h5" color="text.secondary" alignSelf="center">
            Make a search and see the magic.
          </Typography>
        </Box>
      )
    }
  }

  return (
    <>
      <AppHeaderNav links={usrRoutes}>
        <SearchBar isLoading={isLoading} searchHits={searchHits} onSearchBarValueChange={onSearchBarValueChange} onSearchBarOptionSelect={onSearchBarOptionSelect} onBlur={() => setSearchHits([])} />
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
          {
            width < 600 ? <IconButton onClick={() => onLogout()} aria-label="share">
              <LogoutIcon color="primary" />
            </IconButton>
              : <Button
                style={{ alignSelf: "flex-end" }}
                variant="contained"
                disableElevation
                onClick={() => onLogout()}
              >
                Log Out
              </Button>
          }


        </div>
      </AppHeaderNav>
      <br />
      <div style={{ paddingLeft: '16px', paddingRight: '16px' }}>
        {/* {renderSyncButtons()} */}
        <br />
        {RenderSelectedRecipes()}
      </div>

      <Switch>
        {usrRouteListItems.map((route) => (
          <Route
            key={`${route.url}-${route.name}-${route.id}`}
            exact={route.exact || false}
            path={route.url}
            component={route.comp}
          />
        ))}
      </Switch>
    </>
  );
};

export default withRouter(UserDash);
