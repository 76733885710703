import * as React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import TextField from '@mui/material/TextField';
import MuiAutocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

interface PropTypes {
    onSearchBarValueChange: (value: string) => void;
    onSearchBarOptionSelect: (value: number) => void;
    searchHits: any[];
    onBlur: () => void;
    isLoading: boolean;
}

const CustomizedInputBase: React.FC<PropTypes> = (props) => {
    const { onSearchBarValueChange, searchHits, onBlur, onSearchBarOptionSelect, isLoading } = props;
    return (
        <>
            <MuiAutocomplete
                id="free-solo-2-demo"
                clearOnEscape
                clearOnBlur
                freeSolo
                disableClearable
                filterOptions={(x) => x}
                getOptionLabel={option => option.name || option}
                onInputChange={(event) => {
                    if (event.currentTarget.getAttribute('data-id') !== null) {
                        onSearchBarOptionSelect(parseInt(event.currentTarget.getAttribute('data-id')!))
                    }
                    else return;
                }}
                options={searchHits.map((hit: any) => hit)}
                onBlur={onBlur}
                renderOption={(props, option, index) => {
                    const key = `listItem-${index}-${option.id}`;
                    return (
                      <li {...props} data-id={option.id} style={{width: '40ch'}} key={key}>
                        {option['name']}
                      </li>
                    );
                  }}
                renderInput={(params) => (
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                                {isLoading && <CircularProgress size={30} style={{position: 'absolute', top: 5, left: 12}}/>}
                            </SearchIconWrapper>
                            <StyledTextField
                                {...params}
                                placeholder="Search a recipe"
                                disabled={isLoading}
                                onChange={(event) => onSearchBarValueChange(event.target.value)}
                                inputRef={params.InputProps.ref}
                                ref={params.InputProps.ref}
                                InputProps={{
                                    ...params.InputProps,
                                    ref: params.InputProps.ref,
                                    inputRef: params.InputProps.ref,
                                    type: 'search',
                                    "aria-label": "search google maps"
                                }}
                            />
                        </Search>
                )}
            />
        </>
    );
};

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    width: "100%",
    marginLeft: '15px',
    minWidth: '35ch',
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    color: "inherit",
    "& .MuiAutocomplete-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        border: 'none',
        transition: theme.transitions.create("width"),
        width: "100%",
        marginLeft:  `calc(1em + ${theme.spacing(2)})`,
        height: '0.56em',
        [theme.breakpoints.up("sm")]: {
            width: "35ch",
            "&:focus": {
                width: "40ch",
            },
        },
    },
}));


export default CustomizedInputBase;
