import React from "react";
import { ComponentRouteListItem } from "../components/AppHeaderNav";
import AppHeaderNav from "../components/AppHeaderNav";
import { Route, Switch } from "react-router-dom";
import { Home } from "./public-pages/Home";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Link from "@mui/material/Link";

const routes: Array<ComponentRouteListItem> = [
  {
    name: "Home",
    url: "/",
    exact: true,
    comp: Home,
    id: "home",
  },
];

export default function PublicView() {
  return (
    <>
      <AppHeaderNav
        links={routes.map(({ comp: _, ...route }) => route)}
        disableLogo={true}
      >
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
          <Stack spacing={2} direction="row">
            <Link href="/login">
              <Typography
                color="white"
                variant="button"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Login
              </Typography>
            </Link>
            <Link href="/login?register">
              <Typography
                color="white"
                variant="button"
                component="div"
                sx={{ flexGrow: 1 }}
              >
                Register
              </Typography>
            </Link>
          </Stack>
        </div>
      </AppHeaderNav>
      <Switch>
        {routes.map((route) => (
          <Route
            key={`${route.url}-${route.name}`}
            exact={route.exact || false}
            path={route.url}
            component={route.comp}
          />
        ))}
      </Switch>
    </>
  );
}
