import React, { FC, useContext, useMemo } from "react";
import { AuthContext, createUser, loginUser } from "../core/auth";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { parse as queryParse } from "query-string";
import { AuthSchemaType, AuthValidatorSchema, initialAuthValues } from "../components/auth/validator";
import { Formik, Form, FormikHelpers } from "formik";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const Login: FC<RouteComponentProps> = ({ location }) => {
    const { user, changeUser } = useContext(AuthContext);

    const isRegisterPage = useMemo(() => {
        const queryProps = queryParse(location!.search);
        return (queryProps && queryProps["register"] !== undefined);
    }, [location]);

    const formikInitialValues = useMemo(() => {
        return {
            ...initialAuthValues,
            register: isRegisterPage
        }
    }, [isRegisterPage])

    const onSubmit = (payload: AuthSchemaType, { setSubmitting, setErrors }: FormikHelpers<AuthSchemaType>) => {
        const submitFunction =
            isRegisterPage
                ? createUser(payload.email, payload.password, ["user"])
                : loginUser(payload.email, payload.password);

        submitFunction
            .then(user => {
                setSubmitting(false);
                console.log("Logged in");
                changeUser(user);
            })
            .catch(({ code, message }) => {
                console.error(code, message);
                setSubmitting(false);
                setErrors({ message });
            });
    }
    
    if (user) return <Redirect to="/" />;

    return (
        <Box display="flex"
            alignItems="center"
            justifyContent="center"
            height={window.innerHeight - 250}
            width={"100%"} 
            style={{ flexGrow: 1 }}
            >
            {/* <Grid container spacing="1" alignItems="center center"> */}
            <Formik initialValues={formikInitialValues} validationSchema={AuthValidatorSchema} onSubmit={onSubmit}>
                {({
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    values,
                    handleReset,
                    dirty,
                    isSubmitting
                }) => (
                    <Box style={{ alignSelf: 'center' }} component="div" sx={{ p: 4, border: '1px solid grey', borderRadius: '5px', width: '50ch' }}>
                        <Form className="full-width">
                            <Stack spacing={1} direction="column">
                                <TextField
                                    error={touched.email && !!errors.email}
                                    helperText={errors.email}
                                    variant="standard"
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="text"
                                    placeholder="Email Here"
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <TextField
                                    error={touched.password && !!errors.password}
                                    helperText={errors.password}
                                    variant="standard"
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type="password"
                                    placeholder="Password Here"
                                    value={values.password || ''}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {
                                    values.register &&
                                    <TextField
                                        error={touched.passwordconfirm && !!errors.passwordconfirm}
                                        helperText={errors.passwordconfirm}
                                        variant="standard"
                                        id="passwordconfirm"
                                        name="passwordconfirm"
                                        type="password"
                                        label="Confirm Password"
                                        placeholder="Confirm Password"
                                        value={values.passwordconfirm || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                }
                            </Stack>

                            {errors.message && <p className="error">{errors.message}</p>}
                            <br />
                            <Stack spacing={2} direction="row">
                                <Button type="submit" disabled={isSubmitting} variant="contained">Submit</Button>
                                <Button type="submit" onClick={handleReset} disabled={!dirty || isSubmitting} variant="contained">Reset</Button>
                            </Stack>
                        </Form>
                    </Box>
                )}
            </Formik>
            {/* </Grid>
            <Grid item xs></Grid>  */}
        </Box>
    );
};

export default withRouter(Login);
