import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import TimerIcon from '@mui/icons-material/Timer';
import Tooltip from '@mui/material/Tooltip';
import LoopIcon from '@mui/icons-material/Loop';
import CircularProgress from '@mui/material/CircularProgress';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

interface PropTypes {
  recipe: any;
  onRecipeDelete: (id: number) => void;
  onRecipeRefresh: (id: number) => Promise<void>;
}

const PROCESSING_PROC_RATE = 2.5;
const COOKING_PROC_RATE = 2.5;

const RecipeCard: React.FC<PropTypes> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [liked, setLiked] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { recipe, onRecipeDelete, onRecipeRefresh } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const transformMilisecond = (ms: number) => {
    const seconds = (ms / 1000).toFixed(1);
    const minutes = (ms / (1000 * 60)).toFixed(1);
    const hours = (ms / (1000 * 60 * 60)).toFixed(1);
    const days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (parseInt(seconds) < 60) return seconds + " sec";
    else if (parseInt(minutes) < 60) return minutes + " min";
    else if (parseInt(hours) < 24) return hours + " hrs";
    else return days + " Days";
  };

  const calculateEndTime = () => {
    const currentTime = new Date();
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    const ms = currentTime.getTime() - recipe.product.lastUpdated * 1000;

    return transformMilisecond(ms);
  };

  const renderAvatar = () => {
    return (
      <img src={`https://bdocodex.com/items/${recipe.product.icon}.png`} alt="icon"></img>
    )
  }

  const renderIngredientsMicro = () => {
    return (
      recipe.ingredients.map((ing: any, index: number) => (
        <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <img style={{ marginRight: '3px' }} src={`https://bdocodex.com/items/${ing.item[0].icon}.png`} height='30px' alt="icon"></img>
            <Typography variant="body2" color="text.secondary">
              {ing.amount} x {`${ing.item[0].name} (${ing.item[0].id})`}
            </Typography>
          </div>
          {renderPrice(ing.item[0].price)}
        </Box>
      ))
    )
  }

  const parseSilver = (amount: string) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderPrice = (value: string, isProfit?: boolean) => {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <img style={{ marginRight: '5px', paddingBottom: '3px' }} src="https://bdocodex.com/items/new_icon/00000001_special.png" height='22px' alt="icon"></img>
        <Typography variant="body2" color={isProfit === undefined ? "text.secondary" : isProfit ? "success" : "error"}>
          {parseSilver(value)}
        </Typography>
      </Box>
    )
  }

  const renderProductPrice = () => {
    return (
      <Box  display="flex" justifyContent='space-between' alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <img style={{ marginRight: '5px', paddingBottom: '3px' }} src="https://bdocodex.com/items/new_icon/00000001_special.png" height='22px' alt="icon"></img>
          <Typography variant="body2" color="text.secondary">
            {parseSilver(recipe.product.price)}
          </Typography>
        </Box>
        <Tooltip placement="bottom" disableFocusListener disableTouchListener title='Prices are cached for 30mins.'>
          <Box display="flex" paddingLeft="10px" flexDirection="row" alignItems="center">
            <TimerIcon fontSize="small" color="disabled" />
            <Typography marginLeft="5px" variant="caption" display='flex' justifySelf='flex-end' color="text.secondary">
              updated {calculateEndTime()} ago
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    )
  }

  const renderRecipeProfit = () => {
    //(recipe.lifeSkill !== 'ALCHEMY' || recipe.lifeSkill !== 'COOKING') &&
    let cost = 0;
    for (let i = 0; i < recipe.ingredients.length; i++)
      cost += parseInt(recipe.ingredients[i].item[0].price) * parseInt(recipe.ingredients[i].amount);
    const shouldAddProcRate = recipe.product.id !== 16005 && recipe.product.id !== 16004
    const grossProfit = shouldAddProcRate ? PROCESSING_PROC_RATE * parseInt(recipe.product.price) : parseInt(recipe.product.price);
    const tax = grossProfit - grossProfit * 0.855;
    const netProfit = grossProfit - tax - cost;
    const isProfit = netProfit > 0;
    return (
      <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center">
        <Box width="40%" display="flex" flexDirection="column" alignItems="space-between" justifyContent="center">
          <Tooltip placement="left" disableFocusListener disableTouchListener title={shouldAddProcRate ? "Product price x 2.5" : 'Product price'}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Typography variant="body2" color="text.secondary">
                Gross
              </Typography>
              {renderPrice(grossProfit.toFixed())}

            </Box>
          </Tooltip>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Cost
            </Typography>
            {renderPrice(cost.toFixed())}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Tax
            </Typography>
            {renderPrice(tax.toFixed())}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2" color="text.secondary">
              Net profit
            </Typography>
            {renderPrice(netProfit.toFixed(), isProfit)}
          </Box>
        </Box>
      </Box>
    )
  }

  const handleRecipeRefresh = async () => {
    setIsRefreshing(true)
   await onRecipeRefresh(recipe)
    .finally(() => 
    setTimeout(() =>setIsRefreshing(false), 500)
    )
  }

  return (
    <Card sx={{ maxWidth: 450 }}>
      <CardHeader
        avatar={renderAvatar()}
        action={
          <Box display="flex">
            <IconButton disabled={isRefreshing} onClick={() => {
              handleRecipeRefresh();
              }} aria-label="share">
              {
                isRefreshing ? <CircularProgress size={24} />
                : <LoopIcon color="primary" />
              }
            </IconButton>
            <IconButton onClick={() => onRecipeDelete(recipe.product.id)} aria-label="share">
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        }

        title={`${recipe.product.name} (${recipe.product.id})`}
        subheader={renderProductPrice()}
      />
      <CardContent>
        {renderIngredientsMicro()}
        <Divider style={{ marginBottom: '10px', marginTop: '10px' }} />
        {renderRecipeProfit()}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={() => setLiked(!liked)} aria-label="add to favorites">
          <FavoriteIcon color={liked ? 'success' : undefined} />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
      </CardActions>
      <Collapse in={false} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>ingredients:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is absorbed,
            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
            mussels, tucking them down into the rice, and cook again without
            stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don’t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default RecipeCard;