import { ComponentType, FC } from "react";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { AuthRoles } from "../types/auth";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

export type RouteType = {
  name: string;
  url: string;
  allowRoles?: Array<AuthRoles>;
  exact?: boolean;
  id: string;
};

export type ComponentRouteListItem = RouteType & {
  comp: ComponentType;
};

interface AppHeaderNavProps {
  disableLogo?: boolean;
  links: RouteType[];
}

const AppHeaderNav: FC<RouteComponentProps & AppHeaderNavProps> = ({
  links,
  children,
  location,
  disableLogo = false,
}) => {
  return (
    <div>
      <nav>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block", flexGrow: 1 } }}
              >
                BDO Helper
              </Typography>
              {/* <div>
                {(!links || links.length > 1) &&
                  links.map(({ url, name }) => {
                    return (
                      <Link to={url} key={`${url}-${name}`}>
                        <button>{name}</button>
                      </Link>
                    );
                  })}
              </div> */}
              {children || <div />}
            </Toolbar>
          </AppBar>
        </Box>
      </nav>
    </div>
  );
};

export default withRouter(AppHeaderNav);
