import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export function Home() {
  return (
    <Box display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign='center'
      width={"100%"}
      style={{ flexGrow: 1, paddingTop: '50px', flexDirection: 'column' }}>
      <Typography variant="h5" color="text.secondary" alignSelf="center">
        Welcome to the most powerful BDO life skill tool in the world.
      </Typography>
      <Typography paddingTop='5px' variant="h5" color="text.secondary" alignSelf="center">
        In order to get most benefit, please login/register with your credentials.
      </Typography>
    </Box>
  )
}
